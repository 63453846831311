<template>
  <div class="refundList page-gray">
<!--    <div class="searchBox">
      <van-search
          v-model="value"
          shape="round"
          background="rgba(0,0,0,0)"
          placeholder="请输入电话号码后四位"
      />

      <div class="filter" @click="timeClone = time; wayClone = way; openFilter = true">
        <img src="./../../assets/images/shaixuan_active.png" v-if="time !== '' || way !== ''" alt="">
        <img src="./../../assets/images/shaixuan.png" v-else alt="">

        <span :class="{active: time !== '' || way !== ''}">筛选</span>
      </div>
    </div>-->

    <div class="orderList" v-for="(item,index) in dataList" :key="index">
      <div class="item" v-if="null != item.afterSalesId" @click="toDetail(item)">
        <div class="head van-hairline--bottom">
          订单编号：{{item.orderSn}}
          <div style="float: right;" @click.stop="CopyFun(item.orderSn)"> 复制</div>
        </div>
        <div class="head van-hairline--bottom">
          售后编号：{{item.afterSalesSn}}
        </div>
        <div class="goodsItem" v-for="goodItem in item.afterSalesOrderMxes">
          <div class="name">
            <div>{{goodItem.name}}</div>
            <div class="state">
              <img src="../../assets/images/jtk.png" alt="">
              <span>{{ typeList[item.type] }}</span>
            </div>
          </div>
          <div class="goodsInfo">
            <img
                :src="goodItem.productImg"
                alt=""
            />
            <div class="right">
              <div class="price">
                <span>¥</span>
                <span>{{ goodItem.price }}</span>
              </div>
              <div class="sku">数量：{{ goodItem.quantity }}</div>
            </div>
          </div>
        </div>
        <div class="stateBox">
          <div class="left">
            <span class="value">{{ statusList[+item.status+1] }}</span>
            <span class="value">-</span>
            <span class="name">{{ getStatusName(item)}}</span>
          </div>
          <div class="right">
            <van-icon name="arrow" color="#999999" size="12"/>
          </div>
        </div>
      </div>
      <div class="item" @click="toDetail(item)" v-else>
        <div class="head van-hairline--bottom">
          订单编号：{{item.orderSn}}
          <div style="float: right;" @click.stop="CopyFun(item.orderSn)"> 复制</div>
        </div>
        <div class="head van-hairline--bottom">
          退款编号：{{ item.refundSn }}
          <span  style="margin-left: 20%;"> {{ arr[item.orderStatus] }}</span>
        </div>
        <div class="goodsItem" v-for="(product,step) in item.orderReturnItemVos " :key="step">
          <div class="name">
            <div>{{product.name}}</div>
          </div>
          <div class="goodsInfo">
            <img
                :src="product.productImg"
                alt=""
            />
            <div class="right">
              <div class="price">
                <span>¥</span>
                <span>{{product.price}}</span>
              </div>
              <div class="sku">数量：{{ product.quantity }}</div>
            </div>
          </div>
        </div>
        <div class="goodsItem" v-if="item.postageProduct">
          <div class="name">
            <div>邮费商品</div>
            <div class="state">退款审核中</div>
          </div>
        </div>
        <div class="count">退款金额：&nbsp;<span>￥{{ item.totalPrice }}</span>&nbsp;（邮费￥{{ item.postFee }}）&nbsp;&nbsp;&nbsp;
        </div>

<!--        <div class="button van-hairline--top">-->
<!--          <van-button plain round type="primary" color="#D61619" size="mini" @click="revokeApplication(item)">撤销申请</van-button>-->
<!--        </div>-->
      </div>
    </div>


    <van-popup
        v-model="openFilter"
        closeable

        close-icon="close"
        position="bottom"
        round
        :style="{ height: px2rem(320) }"
    >
      <h1>售后申请</h1>

      <div class="filter-item">
        <div class="title">下单时间</div>
        <van-row gutter="22" class="list">
          <van-col :span="8">
            <div :class="{item: true, active: timeClone == 1}" @click="timeClone=1">
              近1个月
            </div>
          </van-col>
          <van-col :span="8">
            <div :class="{item: true, active: timeClone == 3}" @click="timeClone=3">
              近3个月
            </div>
          </van-col>
          <van-col :span="8">
            <div :class="{item: true, active: timeClone == 6}" @click="timeClone=6">
              近6个月
            </div>
          </van-col>
        </van-row>
      </div>

      <div class="buttonGroup">
        <van-row gutter="14">
          <van-col :span="12">
            <van-button block type="info" color="#F2F2F2" round @click="timeClone = ''; wayClone = '';">
              <span style="color: #333;">重置</span>
            </van-button>
          </van-col>
          <van-col :span="12">
            <van-button block type="primary" color="#D53A26" round @click="confirm">确认</van-button>
          </van-col>
        </van-row>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "afterSalesList",
  data() {
    return {
      active: 0,
      value: '',
      time: '',
      way: '',
      timeClone: '',
      wayClone: '',
      openFilter: false,
      //数据
      dataList: [],
      sysTypeList: ['客户申请', '后台申请'],
      typeList: ["仅退款", '退货退款', '换货'],
      statusList: ['发起售后', '厂家审核', '商品寄回', '退款审核','退款','售后完成'],
      arr:["已申请","已确认","已完成","已取消","已驳回"],
    }
  },
  watch:{
    active:{
      handler(val){
        this.active = val;
          this.init();
      },
      immediate:true,
    }
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      let startTime = null;
      if ('' !== this.timeClone) {
        startTime = new Date(new Date().setMonth((new Date().getMonth() - this.timeClone)))

      }
      let endTime = new Date();

      this.$http.get(this.baseMallUrl + "/h5_mall/queryUserAfterSalesRefundRecords",
          {params: {
              startTime: startTime,
              endTime: endTime,
              active: this.active
            }}, {
            emulateJSON: true
          }).then(response => {
        this.dataList = response.data;
      })
    },
    toDetail(item) {
      if(null != item.afterSalesId){
        this.$router.push('/afterSales/detail/'+item.afterSalesId)
      }else{
        this.$router.push('/refund/detail/'+item.refundId)
      }
    },
    confirm() {
      this.time = this.timeClone;
      this.way = this.wayClone;
      this.openFilter = false
    },
    CopyFun(content) {
      let oInput = document.createElement("input");
      oInput.value = content;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$toast.success('复制成功');
      oInput.remove();
    },
    getStatusName(item){
      if(item.failStatus){
        if('0'==item.failStatus){
          return "售后驳回";
        }else if('1'==item.failStatus){
          return "售后撤销";
        }
      }

      return item.status>=4? '已完成' : '处理中';
    }
  },
  // activated (){
  //   this.init();
  // },
  // mounted() {
  //   this.init();
  // }
}

</script>
<style lang="less" scoped>

.count {
  line-height: 12px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #212121;

  padding: 0 13px;
  padding-top: 21px - 13px;
  padding-bottom: 13px;

  span {
    color: #D61619;
  }
}
.refundList {
  display: flex;
  flex-direction: column;

  .orderList {
    flex: 1;

    .item {
      background: #FFFFFF;
      border-radius: 8px;
      margin: 10px 18px;
      padding-bottom: 1px;

      .head {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 30px;

        padding: 0 13px;

        &::after {
          border-color: #eee;
        }
      }

      .stateBox {
        height: 32px;
        background: #F4F4F4;
        border-radius: 3px;
        margin: 0 13px 13px;
        padding: 0 19px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          span.name {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            display: inline-block;
            margin-right: 22px;
          }

          span.value {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
          }
        }
      }

    }
  }
}


.goodsItem {

  padding: 12px 13px;
  background: #fff;

  .name {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    div:first-child {
      font-size: 12px;
      color: #333333;
      line-height: 15px;
      width: 179px;
    }

    .state {
      line-height: 15px;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      display: flex;
      align-items: center;

      img {
        height: 14px;
        width: 14px;
        margin-right: 3px;
      }
    }
  }
}

.goodsInfo {
  display: flex;
  align-items: center;
  margin-top: 9px;

  &.multiple {
    img {
      margin-right: 5px;
    }
  }

  img {
    width: 95px;
    height: 80px;
    border-radius: 5px;
    min-width: 95px;
    margin-right: 20px;
  }

  .right {
    .price {
      span {
        line-height: 1;
        font-weight: bold;
        color: #d53a26;

        &:last-child {
          font-size: 15px;
          margin-left: 3px;
        }

        &:first-child {
          font-size: 12px;
        }
      }
    }

    .sku {
      background: #eeeeee;
      border-radius: 2px;

      padding: 5px 10px;
      line-height: 10px;
      font-size: 10px;
      color: #666666;

      display: inline-block;
    }

    .tagList {
      margin: 7px 0;
      font-size: 0;

      .tag {
        display: inline-block;
        background: #ffffff;
        border: 1px solid #ff0000;
        border-radius: 3px;
        line-height: 10px;
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ff0000;

        padding: 2px 5px;
      }
    }

    .time {
      line-height: 10px;
      font-size: 10px;
      color: #e7ac58;
    }
  }
}


::v-deep .van-search__content {
  background-color: #fff;
}

::v-deep .van-field__control {
  font-size: 12px;
}

.van-search {
  flex: 1;
  padding: 0;
}


h1 {
  line-height: 1;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #212121;
  margin-top: 24px;
  text-align: center;
}

.filter-item {
  margin-top: 35px;
  padding: 0 17px;

  .title {
    font-size: 15px;
    font-weight: 500;
    color: #212121;
    line-height: 1;
    margin-bottom: 13px;
  }

  .list {
    .item {
      height: 32px;
      width: 100%;
      border-radius: 16px;

      font-size: 12px;
      font-weight: 400;

      background: #EEEEEE;
      color: #333333;
      border: 1px solid #EEEEEE;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.active {
        color: #D53A26;
        background: #F8EDEB;
        border: 1px solid #D61619;
      }
    }
  }

}

.buttonGroup {
  padding: 30px 18px 0 18px;
}

.searchBox {
  display: flex;
  padding: 10px 18px 0;

  .filter {
    text-align: center;
    margin-left: 10px;

    img {
      height: 12px;
      width: 12px;
    }

    span {
      line-height: 12px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      display: block;

      &.active {
        color: #D53A26;
      }
    }
  }
}
</style>
